import {v4 as uuidv4} from "uuid";

export default function getProducts(room, austattung_produkt, selectedAlternatives, existingActions){

    const productList = {list:[], listPro: [], actions:[], actionsShared: []};
    let actions = [];
    function getNewUuid() {
        const uuid = uuidv4();
        let found = productList.actions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        found = existingActions.filter(i => i.uuid === uuid)[0] !== undefined;
        if (found) { return getNewUuid(); }
        return uuid;
    }
    function addActionToDevice(cnt, dev, startWithShading = false, puid = []) {
        const aList = [];
        const vstart = actions.filter(i => i.type === 'verschattung')[0];
        if (cnt > 0 && vstart && startWithShading) {
            if (vstart.count > 0) {
                for (let i = 1; i <= vstart.count; i++) {
                    if (cnt > 0) {
                        const newUUID = getNewUuid();
                        const a = {type: 'shading_shutter', uuid: newUUID, device: dev, addedIn: 'AddTaster', puid: ''};
                        puid.forEach(puidId => {
                            productList.actions.push({...a, puid: puidId});
                        });
                        aList.push({...a, puid: puid[0]});
                        cnt -= 1;
                        actions = actions.map(item => item.type === 'verschattung' ? {...item, count: item.count - 1} : item);
                    }
                }
            }
        }
        const sw = actions.filter(i => i.type === 'schalten')[0];
        if (sw) {
            if (sw.count > 0) {
                for (let i = 1; i <= sw.count; i++) {
                    if (cnt > 0) {
                        const newUUID = getNewUuid();
                        const a = {type: 'switch_light', uuid: newUUID, device: dev, addedIn: 'AddTaster', puid: ''};
                        puid.forEach(puidId => {
                            productList.actions.push({...a, puid: puidId});
                        });
                        aList.push({...a, puid: puid[0]});
                        cnt -= 1;
                        actions = actions.map(item => item.type === 'schalten' ? {...item, count: item.count - 1} : item);
                    }
                }
            }
        }
        const sws = actions.filter(i => i.type === 'schalten-steckdose')[0];
        if (sws) {
            if (sws.count > 0) {
                for (let i = 1; i <= sws.count; i++) {
                    if (cnt > 0) {
                        const newUUID = getNewUuid();
                        const a = {type: 'switch_powerplug', uuid: newUUID, device: dev, addedIn: 'AddTaster', puid: ''};
                        puid.forEach(puidId => productList.actions.push({...a, puid: puidId}));
                        aList.push({...a, puid: puid[0]});
                        cnt -= 1;
                        actions = actions.map(item => item.type === 'schalten-steckdose' ? {...item, count: item.count - 1} : item);
                    }
                }
            }
        }
        const d = actions.filter(i => i.type === 'dimmen')[0];
        if (cnt > 0 && d) {
            if (d.count > 0) {
                for (let i = 1; i <= d.count; i++) {
                    if (cnt > 0) {
                        const newUUID = getNewUuid();
                        const a = {type: 'dim', uuid: newUUID, device: dev, addedIn: 'AddTaster', puid: ''};
                        puid.forEach(puidId => productList.actions.push({...a, puid: puidId}));
                        aList.push({...a, puid: puid[0]});
                        cnt -= 1;
                        actions = actions.map(item => item.type === 'dimmen' ? {...item, count: item.count - 1} : item);
                    }
                }
            }
        }
        const v = actions.filter(i => i.type === 'verschattung')[0];
        if (cnt > 0 && v && !startWithShading) {
            if (v.count > 0) {
                for (let i = 1; i <= v.count; i++) {
                    if (cnt > 0) {
                        const newUUID = getNewUuid();
                        const a = {type: 'shading_shutter', uuid: newUUID, device: dev, addedIn: 'AddTaster', puid: ''};
                        puid.forEach(puidId => productList.actions.push({...a, puid: puidId}));
                        aList.push({...a, puid: puid[0]});
                        cnt -= 1;
                        actions = actions.map(item => item.type === 'verschattung' ? {...item, count: item.count - 1} : item);
                    }
                }
            }
        }
        return {actions: aList.filter(i => i.type.indexOf('switch') >= 0 || i.type.indexOf('dim') >= 0)};
    }
    function addExistingActionToDevice(cnt, device, puid) {
        while (assignedOnce.length > 0 && cnt > 0) {
            const a = {...assignedOnce[0], device, puid, addedIn: 'AddTaster-addExistingAction'};
            productList.actionsShared.push(a);
            productList.actions.push(a);
            cnt -= 1;
            assignedOnce.splice(0, 1);
        }
    }
    if(
        room.type in [
            "8142128b-51cd-47ed-8c79-7ef5b2ae83d9", //Treppenhaus
            "d8cf675b-2a77-42b9-8be7-5b9f7b6ff4f9", //Flur/Diele über 3 qm
            "46e3e159-d5a5-4623-9cd6-8f66f5fad7fa", //Kellertreppe / Flur
        ]
        && room.selectedEquipment["melden_bewegung"] !== undefined
        && Boolean(room.selectedEquipment["melden_bewegung"])
    ){
        return productList;
    }

    let countFirst = 0;
    // let countAll = 0;
    let schalterCount = 0;
    let schalterCountShared = 0;
    let assignedOnce = [];


    const checkList = [
        {
            key: "schaltbare_beleuchtung",
            onlyFirst: false
        },
        {
            key: "dimmbare_beleuchtung",
            onlyFirst: false
        },
        {
            key: "schaltbare_steckdosen",
            onlyFirst: false
        },
        {
            key: "verschattung_steuern",
            onlyFirst: true
        }
    ];
    checkList.forEach(item => {
        if(
            room.selectedEquipment[item.key] !== undefined
            && room.selectedEquipment[item.key] > 0
        ){
            // if(!item.onlyFirst) {
            //     countAll += room.selectedEquipment[item.key] * room.doorCount;
            // }
            countFirst += item.onlyFirst ? (room.windowCount) : (room.selectedEquipment[item.key]);
            switch (item.key) {
                case "schaltbare_beleuchtung": //switch_light
                    const asw = actions.filter(i => i.type === 'schalten')[0];
                    if (asw) {
                        actions = actions.map(action => action.type !== 'schalten' ? action : {...action, count: action.count + room.selectedEquipment[item.key]});
                    } else {
                        actions = [...actions, {type: 'schalten', count: room.selectedEquipment[item.key]}];
                    }
                    break;
                case "schaltbare_steckdosen": //switch_powerplug
                    const ass = actions.filter(i => i.type === 'schalten-steckdose')[0];
                    if (ass) {
                        actions = actions.map(action => action.type !== 'schalten-steckdose' ? action : {...action, count: action.count + room.selectedEquipment[item.key]});
                    } else {
                        actions = [...actions, {type: 'schalten-steckdose', count: room.selectedEquipment[item.key]}];
                    }
                    break;
                case "dimmbare_beleuchtung": //dim
                    const ad = actions.filter(i => i.type === 'dimmen')[0];
                    if (ad) {
                        actions = actions.map(action => action.type !== 'dimmen' ? action : {...action, count: action.count + room.selectedEquipment[item.key]});
                    } else {
                        actions = [...actions, {type: 'dimmen', count: room.selectedEquipment[item.key]}];
                    }
                    break;
                case "verschattung_steuern": //shading_shutter
                case "rolllaeden_steuern":
                case "jalousie_steuern":
                    actions = [...actions, {type: 'verschattung', count: room.windowCount}];
                    const ar = actions.filter(i => i.type === 'verschattung')[0];
                    if (ar) {
                        // actions = actions.map(action => action.type !== 'verschattung' ? action : {...action, count: action.count + room.windowCount});
                    } else {
                        // actions = [...actions, {type: 'verschattung', count: 1}];
                    }
                    break;
                default: break;
            }
        }
    });
    // console.log('actions', {...actions}, room)
    if (room.selectedEquipment["heizen_heizkoerper"] && room.selectedEquipment["heizen_heizkoerper"] > 0) {
        if (austattung_produkt["rt-regler"] && room.doorCount > 0) {
            const puid = uuidv4();
            productList.list.push({
                product: austattung_produkt["rt-regler"],
                count: 1,
                products: [austattung_produkt["rt-w"]],
                puid: puid
            });
            // if (austattung_produkt["rt-w"]) {
            //     productList.list.push({
            //         product: austattung_produkt["rt-w"],
            //         count: 1
            //     });
            // }
            // if (austattung_produkt["r-1f"]) {
            //     productList.list.push({
            //         product: austattung_produkt["r-1f"],
            //         count: 1
            //     });
            // }
            schalterCount += 1;
            const operatingConcept = room.selectedDesign.selectedOperationConcept.id.toLowerCase();
            if (["f40", "f50"].includes(operatingConcept)) {
                countFirst -= operatingConcept === "f40" ? 3 : 4;
                let toAdd = 4;
                if (operatingConcept === "f40") { toAdd = 3; }
                const assignedActions = addActionToDevice(toAdd, austattung_produkt["rt-regler"], false, [puid]);
                assignedOnce = assignedOnce.concat(assignedActions.actions);
            }
            productList.actions.push({type: 'heating_PWM', uuid: uuidv4(), device: austattung_produkt["rt-regler"], addedIn: 'AddTaster', puid: puid});
        } else if (austattung_produkt["rt-regler"]) {
            productList.actions.push({type: 'heating_PWM', uuid: uuidv4(), device: '', addedIn: 'AddTaster', puid: ''});
        }
    }
    if (room.selectedEquipment["heizen_fußbodenheizung"] && room.selectedEquipment["heizen_fußbodenheizung"] > 0) {
        if (austattung_produkt["rt-regler"] && room.doorCount > 0) {
            const puid = uuidv4();
            productList.list.push({
                product: austattung_produkt["rt-regler"],
                count: 1,
                products: [austattung_produkt["rt-w"]],
                puid: puid
            });
            // if (austattung_produkt["rt-w"]) {
            //     productList.list.push({
            //         product: austattung_produkt["rt-w"],
            //         count: 1
            //     });
            // }
            // if (austattung_produkt["r-1f"]) {
            //     productList.list.push({
            //         product: austattung_produkt["r-1f"],
            //         count: 1
            //     });
            // }
            schalterCount += 1;
            const operatingConcept = room.selectedDesign.selectedOperationConcept.id.toLowerCase();
            if (["f40", "f50"].includes(operatingConcept)) {
                countFirst -= operatingConcept === "f40" ? 3 : 4;
                let toAdd = 4;
                if (operatingConcept === "f40") { toAdd = 3; }
                const assignedActions = addActionToDevice(toAdd, austattung_produkt["rt-regler"], false, [puid]);
                assignedOnce = assignedOnce.concat(assignedActions.actions);
            }
            productList.actions.push({type: 'heating_PWM', uuid: uuidv4(), device: austattung_produkt["rt-regler"], addedIn: 'AddTaster', puid: puid});
        } else if (austattung_produkt["rt-regler"]) {
            productList.actions.push({type: 'heating_PWM', uuid: uuidv4(), device: '', addedIn: 'AddTaster', puid: ''});
        }
    }
    const taster_list = [];
    if (austattung_produkt["t4"]) { taster_list.push({faktor: 4, key: "t4", pKeys: ["t4", "t4-wippe"]}); }
    if (austattung_produkt["t3"]) { taster_list.push({faktor: 3, key: "t3", pKeys: ["t3", "t3-wippe"]}); }
    if (austattung_produkt["t2"]) { taster_list.push({faktor: 2, key: "t2", pKeys: ["t2", "t2-wippe"]}); }
    if (austattung_produkt["t1"]) { taster_list.push({faktor: 1, key: "t1", pKeys: ["t1", "t1-wippe"]}); }

    const frame_list = [{faktor: 5, key: "r-5f"}, {faktor: 4, key: "r-4f"}, {faktor: 3, key: "r-3f"}, {faktor: 2, key: "r-2f"}, {faktor: 1, key: "r-1f"}]

    if (room.selectedEquipment["verschattung_steuern"] > 0 && room.windowCount > 0) {
        if (room.doorCount > 0) {
            let assigned = false;
            taster_list.sort((a, b) => a.faktor > b.faktor ? -1 : 1).forEach(taster => {
                if ((countFirst) / taster.faktor >= 1 && austattung_produkt[taster.key] !== undefined) {
                    const c = Math.floor((countFirst) / taster.faktor);
                    if (c > 0 && !assigned) {
                        const puid = uuidv4();
                        productList.list.push({
                            product: austattung_produkt[taster.pKeys[0]],
                            count: 1,
                            products: [austattung_produkt[taster.pKeys[1]]],
                            puid: puid
                        });
                        const assignedActions = addActionToDevice(taster.faktor, austattung_produkt[taster.pKeys[0]], true, [puid]);
                        assigned = true;
                        schalterCount += 1;
                        countFirst -= taster.faktor; // - 1;
                        assignedOnce = assignedOnce.concat(assignedActions.actions);
                    }
                }
            });
        } else {
            actions.forEach(a => {
                if (['schalten', 'verschattung'].includes(a.type)) {
                    const cnt = a.count;
                    for (let i = 0; i < cnt; i++) {
                        const newUUID = getNewUuid();
                        productList.actions.push({type: a.type === 'verschattung' ? 'shading_shutter' : 'switch_light', uuid: newUUID, device: '', addedIn: 'AddTaster', puid: ''});
                        actions = actions.map(item => item.type === a.type ? {...item, count: item.count - 1} : item);
                    }
                }
            });
        }
    }
// console.log('countfirst', countFirst, {...actions}, {...assignedOnce})
    if(countFirst > 0){
        taster_list.forEach(taster => {
            if(
                countFirst / taster.faktor >= 1
                && austattung_produkt[taster.key] !== undefined
            ){
                let puids = [];
                const c = Math.floor(countFirst / taster.faktor);
                for (let i = 0; i < c; i++) {
                    const puid = uuidv4(); puids.push(puid);
                    productList.list.push({
                        product: austattung_produkt[taster.pKeys[0]],
                        count: 1,
                        products: [austattung_produkt[taster.pKeys[1]]],
                        puid: puid
                    });
                    const actLst = addActionToDevice(taster.faktor, austattung_produkt[taster.pKeys[0]], false, [puid]);
                    if (room.doorCount > 1) { assignedOnce = assignedOnce.concat(actLst.actions); }
                }
                // addActionToDevice(c * taster.faktor * room.doorCount, austattung_produkt[taster.pKeys[0]], false, puids);
                schalterCountShared += room.doorCount - 1;
                schalterCount += c;
                countFirst -= c * taster.faktor;
                if (countFirst > 0) {
                    const erweiterungKey = room.selectedDesign.selectedOperationConcept.id.toLowerCase() + '-e' + countFirst;
                    const wippeKey = "t" + countFirst + "-wippe";
                    if (austattung_produkt[erweiterungKey]) {
                        puids = [];
                        for (let i = 0; i < room.doorCount; i++) {
                            const puide = uuidv4(); puids.push(puide);
                            productList.list.push({
                                product: austattung_produkt[erweiterungKey],
                                count: 1,
                                products: [austattung_produkt[wippeKey]],
                                puid: puide
                            });
                        }
                        addActionToDevice(countFirst, austattung_produkt[erweiterungKey], false, puids);
                        countFirst -= countFirst;
                        schalterCountShared += room.doorCount - 1;
                        schalterCount += 1;
                    }
                }
            }
        });
    }
    // console.log('actions', {...actions}, room, {...productList})
    // console.log('assignedonce', {...assignedOnce}, room.doorCount)
    if (assignedOnce.length > 0 && room.doorCount > 1) {
        taster_list.forEach(taster => {
            if ( (assignedOnce.length / taster.faktor) >= 1 && austattung_produkt[taster.key] !== undefined) {
                const c = Math.floor(assignedOnce.length / taster.faktor);
                if (c > 0) {
                    for (let i = 0; i < c * (room.doorCount - 1); i++) {
                        const puid = uuidv4();
                        productList.list.push({
                            product: austattung_produkt[taster.pKeys[0]],
                            count: 1,
                            products: [austattung_produkt[taster.pKeys[1]]],
                            puid: puid
                        });
                        addExistingActionToDevice(taster.faktor, austattung_produkt[taster.pKeys[0]], puid);
                        schalterCountShared += 1;
                    }
                }
            }
        });
        let sharedFrames = Math.floor(schalterCountShared / (room.doorCount - 1));
        const shared1fach = schalterCountShared - (sharedFrames * (room.doorCount - 1));
        frame_list.forEach(frame => {
            if (sharedFrames / frame.faktor >= 1 && austattung_produkt[frame.key] !== undefined) {
                const c = Math.floor(sharedFrames / frame.faktor);
                if (c > 0) {
                    productList.list.push({
                        product: austattung_produkt[frame.key],
                        count: c * (room.doorCount - 1),
                    });
                    sharedFrames -= c * frame.faktor;
                }
            }
        });
        let remaining = sharedFrames + shared1fach;
        frame_list.forEach(frame => {
            if (remaining / frame.faktor >= 1 && austattung_produkt[frame.key] !== undefined) {
                const c = Math.floor(remaining / frame.faktor);
                productList.list.push({
                    product: austattung_produkt[frame.key],
                    count: c * (room.doorCount - 1),
                });
                remaining -= c * frame.faktor;
            }
        });
    }
    if (room.doorCount > 0) {
        [{count: schalterCount, doors: 1}].forEach(countObj => {
            if (countObj.count > 0 && countObj.doors > 0) {
                let innerCount = countObj.count;
                frame_list.forEach(frame => {
                    if (innerCount / frame.faktor >= 1 && austattung_produkt[frame.key] !== undefined) {
                        const c = Math.ceil(innerCount / frame.faktor);
                        productList.list.push({
                            product: austattung_produkt[frame.key],
                            count: c * countObj.doors,
                        });
                        innerCount -= c * frame.faktor;
                    }
                });
            }
        });
    }

    return productList;
}
