import React from "react";
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import LabelBox from "./LabelBox/LabelBox";
import CountBox from "./CountBox/CountBox";
import {Switch, Tooltip, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import uarrh from "../images/arrow_up_hover.svg";
import darrh from "../images/arrow_down_hover.svg";
import checked from "../images/checked.png";
import unchecked from "../images/unchecked.png";
import imgdisabled from "../images/disabled.png";

export default function FloorEquipmentComponent({floor, updateFloor, data, opened, column = 1}){

    const [open, setOpen] = React.useState(opened !== undefined ? opened : true);
    function onOpenClose(e){
        e.preventDefault(); e.stopPropagation();
        setOpen(!open);
        return false;
    }
    function updateRoom(room){
        updateFloor({...floor, rooms: floor.rooms.map(r => r.id !== room.id ? r : room)});
    }

    function generateRoomList(){
        const optionCounter = (data.type === "boolean-single-additional") ? data.options.length + 1 : data.options.length;
        const colSpan = "col-" + (6 / optionCounter);
        return floor.rooms.map(r => generateRoom(r, colSpan.replace('.', '')));
    }
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--color2)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '250px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
        },
    }))(Tooltip);
    // function shouldDisable(option) {
    //     if (floor.rooms.filter(i => i.windowCount < 1)[0] !== undefined && option.id === 'verschattung_steuern') { return true; }
    //     if (floor.rooms.filter(i => i.heatingCount < 1)[0] !== undefined && option.id === 'heizen_heizkoerper') { return true; }
    //     if (floor.rooms.filter(i => i.floorheatingCount < 1)[0] !== undefined && option.id === 'heizen_fußbodenheizung') { return true; }
    //     return false;
    // }
    function generateFloorControl(){
        const optionCounter = (data.type === "boolean-single-additional") ? data.options.length + 1 : data.options.length;
        const colSpan = "col-" + (6 / optionCounter);
        switch (data.type) {
            case "count":
                return data.options.map((option, optionIdx) => <div className={"text-center " + colSpan.replace('.', '') + (column === optionIdx + 1 ? ' active' : ' hidden')} key={floor.id + "-" + option.id}></div>);
            case "boolean":
                return data.options.map((option, optionIdx) => <div className={"text-center " + colSpan.replace('.', '') + (column === optionIdx + 1 ? ' active' : ' hidden')} key={floor.id + "-" + option.id}>
                    <Switch checked={isChecked(option)} onChange={(e) => {e.preventDefault(); e.stopPropagation(); setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, [], []);return false;}}/>
                </div>)
            case "boolean-single":
                return data.options.map((option, optionIdx) => <div className={"text-center " + colSpan.replace('.', '') + (column === optionIdx + 1 ? ' active' : ' hidden')} key={floor.id + "-" + option.id}>
                    <Switch checked={isChecked(option)} onChange={(e) => {e.preventDefault();e.stopPropagation();setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, data.options, []);return false;}}/>
                </div>);
            case "boolean-single-additional":
                let additionalSelect = null;
                if(data.type === "boolean-single-additional"){
                    const disabled = isOnePerRoomChecked(data.options);
                    additionalSelect = <div className={"text-center " + colSpan.replace('.', '')} key={floor.id + "-" + data.additionalOption.id}><Switch disabled={isOnePerRoomChecked(data.options)} checked={isChecked(data.additionalOption)} onChange={(e) => {e.preventDefault();e.stopPropagation();setValueForSelectedEquipmentFloor(data.additionalOption.id, e.target.checked ? 1 : 0, [], data.options);return false;}}/></div>;
                    if(disabled && data.additionalOption.hoverText !== undefined){
                        additionalSelect = <HtmlTooltip enterTouchDelay={50} title={data.additionalOption.hoverText}>{additionalSelect}</HtmlTooltip>
                    }
                }
                return <>
                    {data.options.map((option, optionIdx) => <div className={"text-center " + colSpan.replace('.', '') + (column === optionIdx + 1 ? ' active' : ' hidden')} key={floor.id + "-" + option.id}><Switch checked={isChecked(option)} onChange={(e) => {e.preventDefault();e.stopPropagation();setValueForSelectedEquipmentBooleanFloor(option.id, e.target.checked ? 1 : 0, data.options, [data.additionalOption]);return false;}}/></div>)}
                    {additionalSelect}
                </>;
            default:
                return null;
        }
    }
    function isChecked(option){
        var checked = false;
        if (option.id === 'heizen_heizkoerper') {
            floor.rooms.filter(i => i.heatingCount > 0).forEach(r => checked = (checked || Boolean(r.selectedEquipment[option.id])));
        }
        else if (option.id === 'heizen_fußbodenheizung') {
            floor.rooms.filter(i => i.floorheatingCount > 0).forEach(r => checked = (checked || Boolean(r.selectedEquipment[option.id])));
        }
        else if (option.id === 'verschattung_steuern') {
            floor.rooms.filter(i => i.windowCount > 0).forEach(r => checked = (checked || Boolean(r.selectedEquipment[option.id])));
        } else {
            floor.rooms.forEach(r => checked = (checked || Boolean(r.selectedEquipment[option.id])));
        }
        return checked;
    }
    function isOnePerRoomChecked(options){
        var checked = 0;
        floor.rooms.forEach(r => {
            options.forEach(
                option => {if(Boolean(r.selectedEquipment[option.id])){checked++}}
            );
        });
        return (floor.rooms.length !== checked);
    }
    // function valueForFloorCounter(option){
    //     const unique = [...new Set(floor.rooms.map(item => item.selectedEquipment[option.id]))];
    //     return unique.length > 1 ? 0 : (unique[0] !== undefined ? unique[0] : 0);
    // }

    function generateRoomControl(data, room, colSpan){
        switch (data.type) {
            case "count":
                return data.options.map((option, optionIdx) => generateRoomCounter(room, colSpan, option, optionIdx));
            case "boolean":
                return data.options.map((option, optionIdx) => generateRoomSwitch(room, colSpan, option, [], [], false, optionIdx));
            case "boolean-single":
                return data.options.map((option, optionIdx) => generateRoomSwitch(room, colSpan, option, data.options, [], false, optionIdx));
            case "boolean-single-additional":
                return <>
                    {data.options.map((option, optionIdx) => generateRoomSwitch(room, colSpan, option, data.options, [data.additionalOption], false, optionIdx))}
                    {generateRoomSwitch(room, colSpan, data.additionalOption, [], data.options, true)}
                </>;
            default:
                break;
        }
    }

    function generateRoom(room, colSpan){
        return <div className="roomRow row bg-white" key={data.id + "-" + room.id}>
            <div className="col-3 contextBoxHover phone-w-50" style={{minWidth: "30%"}}>
                <div className="openClose"> </div>
                <LabelBox value={room.name} editAble={false} isBold={false}/>
            </div>
            <div className={"col-1"}></div>
            {generateRoomControl(data, room, colSpan)}
        </div>;
    }

    function generateRoomCounter(room, colSpan, option, idx){
        const value = room.selectedEquipment[option.id];
        const maxCount = (option.maxCount !== undefined) ? option.maxCount : 99;
        return <div className={"text-center " + colSpan + (column === idx + 1 ? ' active' : ' hidden')} key={room.id + "-" + option.id}><CountBox minValue={0} maxValue={option.maxCount !== undefined ? option.maxCount : maxCount} value={value ? value : 0} setValue={value => setValueForSelectedEquipment(room, option.id, value)} /></div>
    }
    function generateRoomSwitch(room, colSpan, option, deselectOptions, deselectAdditionalOption, isAdditional, idx){
        var disabled = false;
        if(isAdditional){
            disabled = true;
            deselectAdditionalOption.forEach(o => {if(room.selectedEquipment[o.id] === 1){disabled = false;}})

        }
        if (room.windowCount < 1 && option.id === 'verschattung_steuern') { disabled = true; }
        if (room.heatingCount < 1 && option.id === 'heizen_heizkoerper') { disabled = true; }
        if (room.floorheatingCount < 1 && option.id === 'heizen_fußbodenheizung') { disabled = true; }
        // let content = <Switch disabled={disabled} checked={Boolean(room.selectedEquipment[option.id])} onChange={(e) => {e.preventDefault();e.stopPropagation();setValueForSelectedEquipmentBoolean(room, option.id, e.target.checked ? 1 : 0, deselectOptions, deselectAdditionalOption, e);return false;}}/>;
        let content = <Switch disabled={disabled} checked={Boolean(room.selectedEquipment[option.id])}/>;
        if(disabled && option.hoverText !== undefined){
            content = <HtmlTooltip enterTouchDelay={50} title={option.hoverText}>{content}</HtmlTooltip>
        }
        return <div className={"text-center " + colSpan + (column === idx + 1 ? ' active' : ' hidden')} key={room.id + "-" + option.id}>
            <label onClick={(e) => {
                e.preventDefault();e.stopPropagation();
                setValueForSelectedEquipmentBoolean(room, option.id, !Boolean(room.selectedEquipment[option.id]) ? 1 : 0, deselectOptions, deselectAdditionalOption, e);return false;
            }}>{content}</label>
        </div>;
    }

    function setValueForSelectedEquipment(room, optionId, value){
        room.selectedEquipment[optionId] = value;
        updateRoom(room);
    }
    function setValueForSelectedEquipmentBoolean(room, optionId, value, deselectOptions, deselectAdditionalOption, e){
        if(value === 1){
            deselectOptions.forEach(option => room.selectedEquipment[option.id] = 0);
        }else{
            deselectAdditionalOption.forEach(option => room.selectedEquipment[option.id] = 0);
        }
        room.selectedEquipment[optionId] = value;
        updateRoom(room);
    }
    function setValueForSelectedEquipmentFloor(optionId, value){
        floor.rooms.forEach(r => r.selectedEquipment[optionId] = value);
        updateFloor({...floor});
    }
    function setValueForSelectedEquipmentBooleanFloor(optionId, value, deselectOptions, deselectAdditionalOption){
        if(value === 1){
            floor.rooms.forEach(r => {deselectOptions.forEach(option => r.selectedEquipment[option.id] = 0)});
        }else{
            floor.rooms.forEach(r => {deselectAdditionalOption.forEach(option => r.selectedEquipment[option.id] = 0)});
        }
        floor.rooms.map(r => {
            r.selectedEquipment[optionId] = optionId === 'verschattung_steuern'
                ? (r.windowCount > 0 ? value : r.selectedEquipment[optionId])
                : (optionId === 'heizen_heizkoerper'
                    ? (r.heatingCount > 0 ? value : 0)
                    : (optionId === 'heizen_fußbodenheizung'
                        ? (r.floorheatingCount > 0 ? value : 0)
                        : value));
            return r;
        });
        updateFloor({...floor});
    }
    const CircleButton = withStyles((theme) => ({
        root: {
            cursor: "pointer",
            borderRadius: "22px",
            width: "22px",
            height: "22px",
            textAlign: "center",
            border: "none",
            minWidth: "unset",
            overflow: "hidden",
            padding: "0 0 0 4px",
            margin: "0 7px 0 2px"
        },
    }))(Button);

    return <div className="floorWrapper" key={data.id + "xx" + floor.id} style={{marginBottom: "15px"}}>
        <div className="floorBoxHeader row">
            <div className={"col-3"}>
                <div className="openClose" onClick={(e) => onOpenClose(e)}>
                    {open ? <CircleButton>
                        <img className="icon-to-page-bottom" src={uarrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""} width="25"/>
                    </CircleButton> : <CircleButton>
                        <img className="icon-to-page-bottom" src={darrh} alt={""} width="25" style={{marginRight: "-22px"}} />
                        <img className="icon-to-page-bottom only-hover" src={darrh} alt={""} width="25"/>
                    </CircleButton> }
                </div>
                <LabelBox value={floor.name} editAble={false} isBold={true}/>
            </div>
            <div className={"col-1"} style={{textAlign: "center", padding: "10px 0"}}>{data.type !== 'count' ? "Alle" : ""}</div>
            {generateFloorControl()}
        </div>
        {open ? generateRoomList() : null }
    </div>
}
