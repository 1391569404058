import React, {useEffect} from "react"
import SelectConfLevelComponent from "./SelectConfLevelComponent";
import EquimentSelectComponent from "./EquimentSelectComponent";
// import iIcon from "../images/info_icon.svg";
// import {Tooltip, withStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";

export default function StepTwoComponent({project, equipmentData, updateProject , updateProjectWithNewLevel, trackingObj, isIFrame}){
    const [customPlan, setCustomPlan] = React.useState(false);
    useEffect(() => {
        if (!isIFrame) { setTimeout(() => {document.body.style.overflow = 'unset'; }, 200); }
    }, []);
    function setConfLevel(e, level){
        e.stopPropagation();
        updateProjectWithNewLevel({...project, selectedLevel: level});
    }
    // const HtmlTooltip = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--textColor)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '400px',
    //         minWidth: '150px',
    //         padding: "0px 7px",
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    function updateTracking() {
        let selectedEquipments = [];
        project.floors.forEach(floor => {
            floor.rooms.forEach(room => {
                equipmentData.forEach(ed => {
                    ed.options.forEach(opt => {
                        const found = selectedEquipments.filter(i => i.name === opt.name)[0];
                        let eq;
                        if (found) {
                            eq = Object.assign(found, {count: found.count + parseInt(room.selectedEquipment[opt.id] || 0,10)});
                        } else if (room.selectedEquipment[opt.id]) {
                            eq = {name: opt.name, count: parseInt(room.selectedEquipment[opt.id],10)};
                        }
                        if (eq) { selectedEquipments = [...selectedEquipments.filter(i => i.name !== opt.name), eq]; }
                    });
                });
            });
        });
    }
    updateTracking();
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "10px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
        </>;
    }

    return <div>
        {project.projectType !== 'home' || (project.projectType === 'home' && project.subProjectType !== 'umbau') ? <div className="fs-container ptb-25 mt-15">
            <div className="padding-container">
                <div className="section-title">Ausstattungsumfang definieren {
                    project.projectType !== 'konventionell' || (project.projectType === 'konventionell' && project.subProjectType !== 'umbau')
                        ? <TooltipWithButton props={{noActive: true, title: '', message: 'Die Fachgemeinschaft für effiziente Energieanwendung (HEA) hat mit der „Stern-Installation“ Ausstattungswerte (Anzahl der Steckdosen und Lichtauslässe je Raum) definiert, die unter der Bezeichnung RAL-RG 678 registriert sind. Denn die Qualität der Wohnräume sowie die Güte und Gebrauchstauglichkeit einer elektrischen Anlage wird durch den Ausstattungswert bestimmt, der laut HEA in DIN 18015-2 Elektrische Anlagen in Wohngebäuden, Art und Umfang der Mindestausstattung festgelegt ist. </p><p>In dem Konfigurator werden demnach alle Räume Ihres Gebäudes nach DIN-Norm mit Steckdosen, Anschlussdosen und Schaltern ausgestattet und in der Stückliste aufgeführt. Ausgewählte Räume erhalten zusätzlich eine Dimmfunktion. Eine Rollladensteuerung ist für alle Räume mit Fenstern berücksichtigt.'}} />
                        : null
                }</div>
                <div className="section-text mb-15">Hier legen Sie den Ausstattungsumfang der Elektroinstallation fest, der sich nach Ihrer individuellen Wohnsituation und den verwendeten Elektrogeräten richtet.</div>
                {project.projectType !== 'konventionell' || (project.projectType === 'konventionell' && project.subProjectType !== 'umbau') ? <SelectConfLevelComponent selectedLevel={project.selectedLevel} setLevel={setConfLevel}/> : null}
            </div>
        </div> : null}
        {/*<div className="fs-container mt-15 ptb-25">*/}
        {/*    <div className="padding-container">*/}
        {/*        <div className="section-title"></div>*/}
        {/*        <div className="section-text">Den vordefinierte Ausstattungsumfang der Elektroinstallation können Sie im Verlauf der Konfiguration beliebig individuell anpassen:</div>*/}
        {/*    </div>*/}
        {/*</div>*/}
        { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} opened={idx===0 && window.innerWidth > 800} setLevel={setConfLevel} starsClickable={false} equipmentCustomPlan={customPlan} setEquipmentCustomPlan={setCustomPlan} updateTracking={updateTracking} />)}
        { equipmentData.map( (item, idx) => <EquimentSelectComponent project={project} equipmentData={item} updateProject={updateProject} key={item.id} opened={idx===0 && window.innerWidth > 800} setLevel={setConfLevel} starsClickable={false} equipmentCustomPlan={customPlan} setEquipmentCustomPlan={setCustomPlan} updateTracking={updateTracking} />)}
    </div>
}
