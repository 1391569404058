import React from "react";
import "./CountBox.scss";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

export default function CountBox({value, setValue, minValue, maxValue, cursor=null}){

    const [edit, setEdit] = React.useState(false);

    function onKeyDown(e) {
        if (e && e.target && e.target.value !== undefined) {
            if (e.target.value === '') { updateValue(0); }
        }
    }
    function onKeyUp(e) {
        if (e && e.target && e.target.value !== undefined) {
            if (e.target.value === '') { updateValue(0); }
        } else e.target.value = parseInt(e.target.value, 10)
    }
    function onKeyPress(e){
        if(e.charCode === 13){
            setEdit(false);
        }
    }

    function onChange(e){
        updateValue(parseInt(e.target.value));
    }

    function updateValue(newValue){
        if(newValue < minValue){
            newValue = minValue;
        }
        if(newValue > maxValue){
            newValue = maxValue;
        }
        setValue(newValue);
    }

    function getLabelOrInput(){
        if(edit){
            return <input type="number" max={maxValue} min={minValue} name="inputCount"
                          onChange={onChange}
                          onBlur={(e) => {
                              setEdit(false);
                              e.target.value = parseInt(e.target.value);
                          }}
                          value={value}
                          className="inputLabel"
                          autoFocus={true}
                          onKeyPress={onKeyPress}
                          onKeyUp={onKeyUp}
                          onKeyDown={onKeyDown}
            />
        }else{
            if (cursor && cursor === 'schalterhand') {
                return <>
                    <RemoveIcon className="CountBoxButton" style={{ fontSize: "14px", padding: "2px", cursor: "url(/images/cursors/schalterhand.png) 9.8 14.5, auto"}} onClick={handleDecrementClick}/>
                    <div className="CountBoxValue" onDoubleClick={e => setEdit(true)}>{value === 0 ? '-' : value}</div>
                    <AddIcon className="CountBoxButton" style={{ fontSize: "14px", padding: "2px", cursor: "url(/images/cursors/schalterhand.png) 9.8 14.5, auto"}} onClick={handleIncrementClick}/>
                </>;
            }
            return <>
                <RemoveIcon className="CountBoxButton" style={{ fontSize: "14px", padding: "2px"}} onClick={handleDecrementClick}/>
                <div className="CountBoxValue" onDoubleClick={e => setEdit(true)}>{value === 0 ? '-' : value}</div>
                <AddIcon className="CountBoxButton" style={{ fontSize: "14px", padding: "2px"}} onClick={handleIncrementClick}/>
                </>
        }
    }

    function handleIncrementClick(e){
        e.preventDefault(); e.stopPropagation();
        updateValue(value + 1);
        return false;
    }
    function handleDecrementClick(e){
        e.preventDefault(); e.stopPropagation();
        updateValue(value - 1);
        return false;
    }

    return <div className="CountBoxWrapper">
        {getLabelOrInput()}
    </div>;
}
