import React from "react";
// import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
// import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import FloorEquipmentComponent from "./FloorEquipmentComponent";
import CountBox from "./CountBox/CountBox";
import {Switch, withStyles} from "@material-ui/core";
import SelectConfLevelComponent from "./SelectConfLevelComponent";
// import AddCircleIcon from "@material-ui/icons/AddCircle";
import uarrh from "../images/arrow_up_hover.svg";
import darrh from "../images/arrow_down_hover.svg";
import Button from "@material-ui/core/Button";
// import iIcon from "../images/info_icon.svg";
// import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
// import chevronRight from "@material-ui/icons/ChevronRight";
// import chevronLeft from "@material-ui/icons/ChevronLeft";
import FilterIcon from "@material-ui/icons/Tune";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import iIconActive from "../images/info_icon_active.svg";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";
import imgdisabled from "../images/disabled.png";
import checked from "../images/checked.png";
import unchecked from "../images/unchecked.png";

export default function EquimentSelectComponent({project, equipmentData, updateProject, opened, idx, setLevel, starsClickable = true, allowClose = true, equipmentCustomPlan= false, setEquipmentCustomPlan, noArrow=false, noStars=false, withPadding=false}){

    const [currentColumn, setCurrentColumn] = React.useState(1);
    const [customOpened, setCustomOpened] = React.useState(equipmentData.selectableFor === "project");
    if(project.selectedEquipment === undefined){
        project.selectedEquipment = [];
    }

    function setNextColumn(total) {
        if (currentColumn + 1 > total) { setCurrentColumn(1); }
        else { setCurrentColumn(currentColumn + 1); }
    }
    function setPreviousColumn(total) {
        if (currentColumn - 1 < 1) { setCurrentColumn(total); }
        else { setCurrentColumn(currentColumn - 1); }
    }

    const [open, setOpen] = React.useState(opened === undefined ? equipmentData.selectableFor === "project" || false : opened);
    function onOpenClose(e){
        if (window.innerWidth < 800) { return false; }
        const wTop = window.document.documentElement.scrollTop;
        if (allowClose) {
            setOpen(!open);
        } else if (equipmentData.selectableFor !== "project") {
            setOpen(!open);
        }
        if (e) {
            e.preventDefault(); e.stopPropagation();
            return false;
        }
    }


    function returnStars(){
        if (noStars) { return null; }
        return <SelectConfLevelComponent selectedLevel={project.selectedLevel} setLevel={setLevel} justStars={true} clickAble={starsClickable}></SelectConfLevelComponent>;
    }
    function getControlls(option, optIdx) {
        var colSpan = "col-" + (6/equipmentData.options.length) + ' w-188 flex-center';
        colSpan = colSpan.replace('.', '');
        var controlls = null;
        switch (equipmentData.type) {
            case "count":
                controlls = generateProjectCounter(colSpan, option, optIdx);
                break;
            case "boolean":
                controlls =  generateProjectSwitch(colSpan, option, [], [], false, optIdx);
                break;
            case "boolean-single":
                controlls = generateProjectSwitch(colSpan, option, equipmentData.options, [], false, optIdx);
                break;
            case "boolean-single-additional":
                colSpan = "col-" + (6 / (equipmentData.options.length + 1)) + ' w-188';
                controlls = <>
                    {generateProjectSwitch(colSpan, option, equipmentData.options, [equipmentData.additionalOption], false, optIdx)}
                    {generateProjectSwitch(colSpan, equipmentData.additionalOption, [], equipmentData.options, true)}
                </>;
                break;
            default:
                break;
        }
        return controlls;
    }

    function generateTableHead(withFullCount, isPhone = false){
        const optionCount = (equipmentData.type === "boolean-single-additional") ?  equipmentData.options.length + 1 : equipmentData.options.length;
        const colSpan = "col-" + (6 / optionCount);
        if (!isPhone || (customOpened && equipmentData.selectableFor !== "project")) {
            return <div className="tableHeader row">
                <div className={"text-center " + (equipmentData.selectableFor === "project" ? "col-4" : "col-4")}>
                    <div
                        className={"equimentBoxHeader-text"}>{equipmentData.subtitle.split('\n').map(function (item, key) {
                        return (<span key={key}>{item}<br/></span>)
                    })}</div>
                    {equipmentData.text !== undefined ? (
                        <div className={"equimentBoxHeader-text bottomLeft"}>{equipmentData.text}</div>) : null}
                </div>
                {equipmentData.options.map((option, optIdx) => {
                    let content = <div
                        className={"text-center " + colSpan.replace('.', '') + ' w-188' + (currentColumn === (optIdx + 1) ? ' active' : ' hidden')}
                        key={equipmentData.id + "-" + option.id} onClick={(e) => onOpenClose(e)}>
                        {option.info
                            ? <div className={"equipment-with-info"}>
                                <img src={process.env.PUBLIC_URL + "/images/austattungen/" + option.icon}
                                     alt={""} style={{maxWidth: "100%", maxHeight: "100%", height: "42px", cursor: "pointer"}}
                                     onMouseOver={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.iconOver : null)}
                                     onMouseOut={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.icon : null)}
                                />
                                {option.bigInfo
                                    ? <TooltipWithButton props={{title: '', message: project.projectType === 'home' && project.subProjectType === 'umbau' && option.infoUmbau ? option.infoUmbau : option.info}} />
                                    : <TooltipWithButton props={{title: '', message: project.projectType === 'home' && project.subProjectType === 'umbau' && option.infoUmbau ? option.infoUmbau : option.info}} />
                                }
                            </div>
                            : <img src={process.env.PUBLIC_URL + "/images/austattungen/" + option.icon}
                                   alt={""} style={{maxWidth: "100%", maxHeight: "100%", height: "42px", cursor: "pointer"}}
                                   onMouseOver={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.iconOver : null)}
                                   onMouseOut={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.icon : null)}
                            />}
                        {withFullCount ? <div className="optionCounter"
                                              style={{cursor: "pointer"}}>{getFullCount(option, equipmentData.type, true)}</div> : null}
                        <div className={"svgDescription-step-two" + (withPadding ? ' wPadding' : '')}
                             style={{cursor: "pointer"}} dangerouslySetInnerHTML={{__html: option.name}}>
                        </div>
                    </div>;
                    if (option.iconToolTip) {
                        return <TooltipWithButton props={{title: '', message: option.iconToolTip}}>{content}</TooltipWithButton>
                    }
                    return content;
                })}
                {(equipmentData.type === "boolean-single-additional")
                    ? <div className={"text-center " + colSpan.replace('.', '')} key={equipmentData.id + "-" + equipmentData.additionalOption.id}>
                            {withFullCount ? <div className="optionCounter">{getFullCount(equipmentData.additionalOption, equipmentData.type)}</div> : null}
                            <img src={process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon}
                                alt={equipmentData.additionalOption.iconTitle ? equipmentData.additionalOption.iconTitle : ""}
                                style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}
                                onMouseOver={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.iconOver : null)}
                                onMouseOut={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon : null)}
                            />
                            <div className={"svgDescription-step-two" + (withPadding ? ' wPadding' : '')}>{equipmentData.additionalOption.name}</div>
                    </div> : null
                }
                {equipmentData.options.length > 1
                    ? <>
                        <a style={{color: "inherit"}} href={"/"} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setNextColumn(equipmentData.options.length);
                            return false;
                        }} className={"next-button"}>
                            <ChevronRight/>
                        </a>
                        <a style={{color: "inherit"}} href={"/"} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            setPreviousColumn(equipmentData.options.length);
                            return false;
                        }} className={"previous-button"}>
                            <ChevronLeft/>
                        </a>
                    </>
                    : null
                }
            </div>;
        }
        return <>
            <div className="tableHeader row">
                <div className={"text-center "} style={{width: '100%', maxWidth: '100%'}}>
                    <div className={"equimentBoxHeader-text"} style={{width: '100%'}}>{
                        equipmentData.subtitle.split('\n').map(function (item, key) {return (<span key={key}>{item}<br/></span>)})
                    }</div>
                    {equipmentData.text !== undefined ? (
                        <div className={"equimentBoxHeader-text bottomLeft"}>{equipmentData.text}</div>) : null}
                </div>
                {(equipmentData.type === "boolean-single-additional") ?
                        <div className={"text-center " + colSpan.replace('.', '')} key={equipmentData.id + "-" + equipmentData.additionalOption.id}>
                            {withFullCount ? <div className="optionCounter">{getFullCount(equipmentData.additionalOption, equipmentData.type)}</div> : null}
                            <img src={process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon}
                                 alt={equipmentData.additionalOption.iconTitle ? equipmentData.additionalOption.iconTitle : ""}
                                 style={{maxWidth: "100%", maxHeight: "100%", height: "42px"}}
                                 onMouseOver={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.iconOver : null)}
                                 onMouseOut={e => (equipmentData.additionalOption.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + equipmentData.additionalOption.icon : null)}
                            />
                            <div className={"svgDescription-step-two" + (withPadding ? ' wPadding' : '')}>{equipmentData.additionalOption.name}</div>
                        </div>
                    : null
                }
            </div>
            <div className={"phone-row" + (equipmentData.options.length < 2 ? " justOne" : "")} style={{width: '100%', marginTop:'30px'}}>
                {equipmentData.options.map((option, optIdx) => {
                    let content = <div className={"text-center flex-column w-188"} key={equipmentData.id + "-" + option.id} style={{justifySelf: 'center'}}>
                        <img src={process.env.PUBLIC_URL + "/images/austattungen/" + option.icon}
                             alt={""} style={{maxWidth: "100%", maxHeight: "100%", height: "42px", cursor: "pointer"}}
                             onMouseOver={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.iconOver : null)}
                             onMouseOut={e => (option.iconOver ? e.currentTarget.src = process.env.PUBLIC_URL + "/images/austattungen/" + option.icon : null)}
                        />
                        {withFullCount ? <div className="optionCounter"
                                              style={{cursor: "pointer"}}>{getFullCount(option, equipmentData.type, true)}</div> : null}
                        {option.info
                            ? <div className={"svgDescription-step-two" + (withPadding ? ' wPadding' : '')}
                                   style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: option.infoIconTop ? 'flex-start' : 'center'
                                }}>{option.name}
                                    {option.bigInfo
                                        ? <TooltipWithButton props={{title: '', message: project.projectType === 'home' && project.subProjectType === 'umbau' && option.infoUmbau ? option.infoUmbau : option.info}} />
                                        : <TooltipWithButton props={{title: '', message: project.projectType === 'home' && project.subProjectType === 'umbau' && option.infoUmbau ? option.infoUmbau : option.info}} />
                                    }
                                </div>
                            </div>
                            : <div className={"svgDescription-step-two" + (withPadding ? ' wPadding' : '')}
                                   style={{cursor: "pointer"}} dangerouslySetInnerHTML={{__html: option.name}}></div>}
                        {equipmentData.selectableFor === "project" ? getControlls(option, optIdx) : null}
                    </div>;
                    if (option.iconToolTip) {
                        return <TooltipWithButton props={{title: '', message: option.iconToolTip}}>{content}</TooltipWithButton>
                    }
                    return content;
                })}
            </div>
        </>;
    }

    function getFullCount(option, type, justCount = false){
        var count = 0;
        var fullCount = 0;
        switch (type) {
            case "boolean":
                project.floors.forEach(
                    floor => floor.rooms.forEach(
                        room => {
                            if(room.selectedEquipment[option.id]){ count++};
                            fullCount++;
                        }
                    )
                );
                return justCount ? <>{count}</> : <>{count}/{fullCount}</>;
            case "count":
            default:
                project.floors.forEach(
                    floor => floor.rooms.forEach(
                        room => {
                            if(room.selectedEquipment[option.id]){count += room.selectedEquipment[option.id];};
                            fullCount++;
                        }
                    )
                );
                return <>{count}</>;
        }
    }

    function updateFloor(floor){
        updateProject({...project, floors: project.floors.map(f => f.id !== floor.id ? f : floor)});
    }

    function generateTables(){
        return project.floors.map((floor, idx) =>
            <FloorEquipmentComponent floor={floor} data={equipmentData} updateFloor={updateFloor} key={equipmentData.id + "-" + floor.id} opened={idx === 0} column={currentColumn} />
        );
    }

    function generateEquipmentSelectComponentInnerBox(){
        if(open){
            if(equipmentData.selectableFor === "project"){
                var colSpan = "col-" + (6/equipmentData.options.length) + ' w-188';
                colSpan = colSpan.replace('.', '');
                var controlls = null;
                switch (equipmentData.type) {
                    case "count":
                        controlls = equipmentData.options.map((option, optionIdx) => generateProjectCounter(colSpan, option, optionIdx));
                        break;
                    case "boolean":
                        controlls = equipmentData.options.map((option, optionIdx) => generateProjectSwitch(colSpan, option, [], [], false, optionIdx));
                        break;
                    case "boolean-single":
                        controlls = equipmentData.options.map((option, optionIdx) => generateProjectSwitch(colSpan, option, equipmentData.options, [], false, optionIdx));
                        break;
                    case "boolean-single-additional":
                        colSpan = "col-" + (6 / (equipmentData.options.length + 1)) + ' w-188';
                        controlls = <>
                            {equipmentData.options.map((option, optionIdx) => generateProjectSwitch(colSpan, option, equipmentData.options, [equipmentData.additionalOption], false, optionIdx))}
                            {generateProjectSwitch(colSpan, equipmentData.additionalOption, [], equipmentData.options, true)}
                        </>;
                        break;
                    default:
                        break;
                }
                return <>
                    {/*{(equipmentData.text !== undefined) ? <div className={"equimentBoxHeader-text"}>{equipmentData.text}</div> : null}*/}
                    <div className="projectBoxHeader row">
                        <div className={"col-3"} style={{color: 'var(--textColor)', fontSize:'14px'}}>
                            {equipmentData.footNote
                                ? <><div>{equipmentData.footNote}</div></>
                                : null}
                        </div>
                        <div className={"col-1"} style={{textAlign: "center", padding: "10px 0"}}></div>
                        {controlls}
                    </div>
                </>
            }else{
                return <>
                    {generateTables()}
                </>
            }
        }
        return null;
    }

    function generateProjectCounter(colSpan, option, idx){
        if (option.type !== undefined) {
            if (option.type === 'boolean') { return generateProjectSwitch(colSpan, option, [], [], false); }
        }
        const value = project.selectedEquipment[option.id];
        const maxCount = (option.maxCount !== undefined) ? option.maxCount : 99;
        return <div className={"text-center " + colSpan + (currentColumn === idx + 1 ? ' active' : ' hidden')} key={option.id}><CountBox minValue={0} maxValue={maxCount} value={value ? value : 0} setValue={value => setValueForSelectedEquipment(option.id, value)} /></div>
    }

    // const HtmlTooltip = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--color2)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '250px',
    //         minWidth: '150px',
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    // const HtmlTooltipBig = withStyles((theme) => ({
    //     tooltip: {
    //         backgroundColor: "var(--weiss)",
    //         color: "var(--color2)",
    //         fontSize: "14px",
    //         border: '1px solid #dadde9',
    //         maxWidth: '500px',
    //         minWidth: '500px',
    //         boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    //     },
    // }))(Tooltip);
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? (!params.noActive ? ' inactive' : '') : '') + (params.absolutePosition ? ' absolutePosition' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"paramActive"}/>
                    </span>
                    : (!params.noActive
                            ? <span className="info-icon">
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                            <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                        </span>
                            : <span>
                            <img src={iIconBlue} width="32" height="32" style={{marginTop: "10px"}} alt={"info"} className={"paramNoActive"}/>
                        </span>
                    )
                }
            </Button>
            <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p dangerouslySetInnerHTML={{__html: params.message}}></p>
                </div>
            </Popover>
        </>;
    }

    function generateProjectSwitch(colSpan, option, deselectOptions, deselectAdditionalOption, isAdditional, idx){
        var disabled = false;
        if(isAdditional){
            disabled = true;
            deselectAdditionalOption.forEach(o => {if(project.selectedEquipment[o.id] === 1){disabled = false;}})
        }

        var content = <div className={"text-center " + colSpan + (currentColumn === (idx + 1) ? ' active' : ' hidden') + ' idx_'+idx + ' column_'+currentColumn} key={option.id}>
            <Switch disabled={disabled} checked={Boolean(project.selectedEquipment[option.id])} onChange={e => setValueForSelectedEquipmentBoolean(option.id, e.target.checked ? 1 : 0, deselectOptions, deselectAdditionalOption, e)}/>
        </div>;

        if(disabled && option.hoverText !== undefined){
            content = <TooltipWithButton props={{title: '', message: option.hoverText}}>{content}</TooltipWithButton>
        }

        return content
    }

    function setValueForSelectedEquipment(optionId, value){
        project.selectedEquipment[optionId] = value;
        updateProject(project);
    }
    function setValueForSelectedEquipmentBoolean(optionId, value, deselectOptions, deselectAdditionalOption, e){
        e.preventDefault(); e.stopPropagation();
        if(value === 1){
            deselectOptions.forEach(option => project.selectedEquipment[option.id] = 0);
        }else{
            deselectAdditionalOption.forEach(option => project.selectedEquipment[option.id] = 0);
        }
        project.selectedEquipment[optionId] = value;
        project.selectedEquipment = Object.assign({}, project.selectedEquipment);
        updateProject({...project});
        return false;
    }
    function setOpenState(e) {
        if (e) { e.preventDefault(); e.stopPropagation(); }
        setOpen(!open);
        return false;
    }
    function ChangeCustomPlan() {
        if (noArrow && equipmentData.selectableFor === 'project') { return <div></div>; }
        return <div className="button customPlan" onClick={e => {setOpenState(e)}}>
            {open ? <CircleButton>
                <img className="icon-to-page-bottom" src={uarrh} alt={""} width="25" />
                <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""} width="25" />
            </CircleButton> : <CircleButton>
                <img className="icon-to-page-bottom" src={darrh} alt={""} width="25" />
                <img className="icon-to-page-bottom only-hover" src={darrh} alt={""} width="25" />
            </CircleButton>}
            <span>Individuell planen</span>
        </div>;
    }
    const CircleButton = withStyles((theme) => ({
        root: {
            cursor: "pointer",
            borderRadius: "22px",
            width: "22px",
            height: "22px",
            textAlign: "center",
            border: "none",
            minWidth: "unset",
            overflow: "hidden",
            padding: "0 0 0 4px",
            margin: "0 20px 0 2px"
        },
    }))(Button);
    if (equipmentData.hidden !== undefined) {
        return null;
    }
    return <div className="fs-container mt-15 ptb-25">
        <div className={"equimentSelectComponent" + (equipmentData.selectableFor === "project" ? " abspos" : "")}>
            <div className="equimentBoxHeader" onClick={(e) => onOpenClose(e)}>
                {/*{noArrow ? <div className={"openClose"}></div> :*/}
                {/*    <div className="openClose">*/}
                {/*        {open ? <CircleButton>*/}
                {/*            <img className="icon-to-page-bottom" src={uarrh} alt={""} width="25" style={{marginRight: "-22px"}} />*/}
                {/*            <img className="icon-to-page-bottom only-hover" src={uarrh} alt={""} width="25"/>*/}
                {/*        </CircleButton> : <CircleButton>*/}
                {/*            <img className="icon-to-page-bottom" src={darrh} alt={""} width="25" style={{marginRight: "-22px"}} />*/}
                {/*            <img className="icon-to-page-bottom only-hover" src={darrh} alt={""} width="25"/>*/}
                {/*        </CircleButton>}*/}
                {/*    </div>*/}
                {/*}*/}
                <span className={"equimentBoxHeader-title"} style={{marginLeft: noArrow ? "0" : "0px"}}>
                    {equipmentData.title}
                    {equipmentData.info
                        ? <TooltipWithButton props={{title: '', message: equipmentData.info}} />
                        : null}
                </span>
                <div>{project.projectType === 'konventionell' && project.subProjectType !== 'umbau' ? returnStars() : null}</div>
            </div>
            {equipmentData.selectableFor !== "project" ? <div className={"customOpener phoneShow" + (customOpened ? ' opened' : '')} style={{margin: "15px 0"}}>
                <a href="/" onClick={(event) => {event.preventDefault(); setCustomOpened(!customOpened); setOpen(!customOpened); return false;} }>
                    <FilterIcon />
                    <div>{customOpened ? ("Schließen") : ("Bearbeiten")}</div>
                </a>
            </div> : null}
            {/*<div className={"equimentBoxHeader-subtitle"}>{equipmentData.subtitle.split('\n').map(function (item, key) {return (<span key={key}>{item}<br/></span>)})}</div>*/}
            <div className={"tableStickyHeader background-grau stickyTop126 phoneNoShow" + (equipmentData.selectableFor === "project" ? " noposition" : "")}>
                {generateTableHead(equipmentData.selectableFor === "project" ? false : true)}
            </div>
            <div className={"tableStickyHeader background-grau stickyTop126 phoneShow" + (equipmentData.selectableFor === "project" ? " noposition" : "")} style={{flexDirection: 'column', width:'100%'}}>
                {generateTableHead(equipmentData.selectableFor === "project" ? false : true, true)}
            </div>
            { generateEquipmentSelectComponentInnerBox() }
            { <ChangeCustomPlan /> }
        </div>
    </div>;
}
