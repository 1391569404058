import {useParams} from "react-router-dom";
import ProjectComponent from "./ProjectComponent";
import React, {useEffect} from "react";
import {GenerateRoomToAddService} from "../services/GenerateRoomToAddService";
import {v4 as uuidv4} from "uuid";
import roomList from "../data/availableRooms.json";
import initialProjectData from "../data/initialProjectData.json";
import schalterdesigns from "../data/schalterdesign.json";
import schalterdesigns_konventionell from "../data/schalterdesign_konventionell.json";
import schalterdesigns_home from "../data/schalterdesign_home.json";
import {ProjectSaveService} from "../services/ProjectSaveService";

export default function StartComponent({isMyJung = false}){
    const [trackingId, setTrackingId] = React.useState(null);
    const params = useParams();
    const [params2] = React.useState(loadParams(params));
    const [isExpert] = React.useState(loadIsExpert);
    const [isKonventionell] = React.useState(loadIsKonventionell);
    const [isHome] = React.useState(loadIsHome);
    const [isIFrame] = React.useState(loadIsIFrame);
    const [loadedCode, setLoadedCode] = React.useState(loadCode);
    const projectSelectList = [...initialProjectData];
    const [project, setProject] = React.useState(initProject);
    const [currentStep, setCurrentStep] = React.useState(1);
    const [uiState, setUiState] = React.useState(getInitialUiState);
    const [offsetTopPos, setOffsetTopPos] = React.useState(0);
    const [scrollTop, setScrollTop] = React.useState(0);
    const [projectModified, setProjectModified] = React.useState(true);
    const [projectCode, setProjectCode] = React.useState(null);
    const [lastSum, setLastSum] = React.useState(null);

    function loadParams(params){
        if(params.confType !== undefined){
            // let paramList = (params['*']).split('/');
            const isKonventionell = params.confType === 'schalter';
            const isHome = (params.confType === 'home');
            var isiframe = false;
            try {
                isiframe =  window.self !== window.top;
            } catch (e) {
                isiframe = true;
            }
            return {isExpert: false, code: params.code, isKonventionell: isKonventionell, isHome: isHome, isiFrame: isiframe, isMyJung: isMyJung};
        }
    }

    function loadCode(){
        if(params.code !== undefined && params.code.length > 0){
            return params.code;
        }
        return undefined;
    }
    function loadIsExpert(){
        if(params2.isExpert !== undefined){
            return params2.isExpert;
        }
        return undefined;
    }
    function loadIsKonventionell(){
        if(params2.isKonventionell !== undefined){
            return params2.isKonventionell;
        }
        return undefined;
    }
    function loadIsHome(){
        return true;
        if(params2.isHome !== undefined){
            return params2.isHome;
        }
        return undefined;
    }
    function loadIsIFrame(){
        if(params2.isiFrame !== undefined){
            return params2.isiFrame;
        }
        return undefined;
    }

    function initProject(projectType = undefined, subProjectType = ''){
        GenerateRoomToAddService.resetDefaultDesignConfig();
        if (projectType === undefined && isKonventionell) { projectType = 'konventionell'; }
        if (projectType === undefined && isHome) { projectType = 'home'; }
        else if (projectType === undefined) { projectType = 'knx'; }
        if (!trackingId) {
            ProjectSaveService.saveTracking({
                trackingId: null, projectType, b2b: isMyJung, step: 1
            }, setTrackingId);
        } else {
            ProjectSaveService.saveTracking({
                trackingId: trackingId.trackingId, projectType, b2b: isMyJung,
            },undefined, trackingId);
        }
        setTimeout(() => { setProjectModified(true); setProjectCode(null); }, 100);
        return {
            ...generateProject(projectSelectList[0], projectType, subProjectType || 'neubau'),
            projectType: projectType || "knx",
            subProjectType: subProjectType || "neubau",
            selectedMusterIx: 0,
            projectName: "",
            isExpert,
            isKonventionell,
            isMyJung: isMyJung
        };
    }
    function generateProject(projectIn, projectType = undefined, subProjectType = '', justlevel = false){
        const selectedLevel = typeof projectIn.selectedLevel != "undefined" ? projectIn.selectedLevel : 1;
        const floors = projectIn.floors;
        const schalterdesign = getSchalterDesign(projectType || projectIn.projectType || "knx");
        GenerateRoomToAddService.resetDefaultDesignConfig();
        const floorToUse = floors.map( i => {
                return {
                    ...i,
                    id: i.id ? i.id : uuidv4(),
                    rooms: i.rooms.map(r => {
                        if (projectType) { r = {...r, selectedDesign: undefined}; }
                        let equipment = getDefaultEquipmentForSelectedLevel(r.type, selectedLevel);
                        if (Boolean(equipment['heizen_fußbodenheizung'])) {
                            // https://feelsmart-pro.atlassian.net/browse/FEEL-231
                            if (r.floorheatingCount < 1 && r.heatingCount > 0) {
                                equipment['heizen_heizkoerper'] = equipment['heizen_fußbodenheizung'];
                                delete equipment['heizen_fußbodenheizung'];
                            }
                        }
                        if (subProjectType === 'umbau') {
                            const skipEquipment = ['telefon_datenanschluss', 'radio_datenanschluss_sat', 'telefon_datenanschluss', 'telefonanschlusss', 'radio_datenanschluss_kabel'];
                            Object.keys(equipment).forEach(key => {
                                equipment = {...equipment, [key]: skipEquipment.includes(key) ? equipment[key] : 0};
                            });
                            const originalValues = {doorCount: r.doorCount, floorheatingCount: r.floorheatingCount, heatingCount: r.heatingCount, windowCount: r.windowCount};
                            r = {...r, doorCount: 0, floorheatingCount: 0, heatingCount: 0, windowCount: 0, originalValues: originalValues};
                        } else if (r.originalValues && !justlevel) {
                            if (r.doorCount === 0 && r.windowCount === 0 && r.floorheatingCount === 0 && r.heatingCount === 0) {
                                r = {...r, doorCount: r.originalValues.doorCount, windowCount: r.originalValues.windowCount, floorheatingCount: r.originalValues.floorheatingCount, heatingCount: r.originalValues.heatingCount};
                            }
                        } else if (!justlevel) {
                            const originalValues = {doorCount: r.doorCount, floorheatingCount: r.floorheatingCount, heatingCount: r.heatingCount, windowCount: r.windowCount};
                            r = {...r, originalValues: originalValues};
                        }
                        return {
                            ...r,
                            id: r.id ? r.id : uuidv4(),
                            selectedDesign: r.selectedDesign ? r.selectedDesign : GenerateRoomToAddService.getDefaultDesignConfig(schalterdesign),
                            selectedEquipment: equipment
                        }
                    })
                };
            }
        );
        if (projectType && projectIn.projectType !== projectType) { projectIn = {...projectIn, projectType: projectType}; }
        return {
            ...projectIn,
            selectedLevel: selectedLevel,
            // floors: subProjectType === 'umbau' || projectIn.subProjectType === 'umbau' ? [] : floorToUse,
            floors: floorToUse,
            projectType: projectIn.projectType ? projectIn.projectType : "knx"
        };
    }
    function getDefaultEquipmentForSelectedLevel(type, selectedLevel){
        let newObj = {};
        const list1 = roomList.filter(rl => (rl.type === type));
        const list2 = list1.length > 0 ? (list1[0]).defaultEquipment.filter(se => (se.level === selectedLevel)) : [];
        if (!list2.length) { return newObj; }
        Object.keys(list2[0].values).map(key => {
            newObj = {...newObj, [key]: list2[0].values[key]};
        });
        return newObj;
        // return list2.length > 0 ? list2[0].values : {};
    }
    function setSelectedProject(projectIn, projectType = 'knx', subProjectType = 'neubau'){
        setProject(generateProject(
            {...projectIn, projectName: project.projectName, projectType: project.projectType, subProjectType: projectIn.subProjectType},
            projectIn.projectType, projectIn.subProjectType || subProjectType
        ));
    }
    function getSchalterDesign(projectType){
        switch (projectType) {
            case "knx":
                return schalterdesigns;
            case "enet":
                return schalterdesigns;
            case "konventionell":
                return schalterdesigns_konventionell;
            case "home":
                return schalterdesigns_home;
            default:
                return schalterdesigns;
        }
    }
    function getInitialUiState(projectType = undefined){
        const design = getSchalterDesign(projectType || project.projectType);
        var serieIx = design.findIndex(d => d.isDefault);
        serieIx = serieIx > 0 ? serieIx : 0;
        var operatingConceptIx = design[serieIx].operatingConcepts.findIndex(d => d.isDefault);
        operatingConceptIx = operatingConceptIx > 0 ? operatingConceptIx : 0;
        var tasterIx = design[serieIx].operatingConcepts[operatingConceptIx].colorsTaster.findIndex(d => d.isDefault);
        tasterIx = tasterIx > 0 ? tasterIx : 0;
        var rahmenIx = design[serieIx].operatingConcepts[operatingConceptIx].colorsTaster[tasterIx].colorsRahmen.findIndex(d => d.isDefault);
        rahmenIx = rahmenIx > 0 ? rahmenIx : 0;
        return {
            selectedSerie: serieIx,
            selectedOperationConcept: operatingConceptIx,
            selectedColorTaster: tasterIx,
            selectedColorRahmen: rahmenIx,
        };
    }
    function startWithProject(projectIn){
        GenerateRoomToAddService.resetDefaultDesignConfig();
        setProject(projectIn);
        setCurrentStep(5);
    }

    if(loadedCode !== undefined && loadedCode.length > 0){
        ProjectSaveService.loadProject(loadedCode, startWithProject);
        setLoadedCode(undefined);
    }
    return <ProjectComponent
        isExpert={isExpert}
        isKonventionell={isKonventionell}
        project={project}
        setProject={setProject}
        setSelectedProject={setSelectedProject}
        initProject={initProject}
        generateProject={generateProject}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        projectSelectList={projectSelectList}
        getSchalterDesign={getSchalterDesign}
        uiState={uiState}
        setUiState={setUiState}
        getInitialUiState={getInitialUiState}
        startWithProject={startWithProject}
        isIFrame={isIFrame}
        trackingObj={trackingId}
        isHome={isHome}
        setTrackingId={setTrackingId}
        isMyJung={isMyJung}
        offsetTopPos={offsetTopPos}
        scrollTop={scrollTop}
        setPrCode={setProjectCode}
        prCode={projectCode}
        lastSum={lastSum}
        setLastSum={setLastSum}
    />

}
