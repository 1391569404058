
export class ProjectBoerseService {

    static version = "0.1";

    static getDomain(){
        const isQM = window.location.hostname.indexOf('planung-qm') >= 0 || window.location.hostname.indexOf('jsp-qm') >= 0;
        console.log('getDomain url', window.location.hostname, 'isQM = ', isQM);
        if (isQM) { return process.env.REACT_APP_BOERSE_API_UR_QM || 'https://qm.xn--projektbrse-yfb.de'; }
        return process.env.REACT_APP_BOERSE_API_UR || 'https://pb-intern.b-onweb.de';
    }

    static sendLogin(data, callFunction) {
        fetch(
            this.getDomain() + "/api/login_check",
            {
                method: "post",
                mode: "cors",
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({username: "Jung_Schnellplaner", password: "Jung_Schnellplaner"})
            }
        ).then(res => res.json()).then(result => {
            fetch(this.getDomain() + "/api/projekt_typen", {
                method: "get", mode: "cors",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + result.token }
            }).then(r => r.json()).then(rr => {
                // console.log('rr', rr)
                result = {...result, projectType: []};
                if (rr['hydra:member']) {
                    rr['hydra:member'].forEach(prTyp => {
                        if (prTyp.name && prTyp.name === 'Smart Home Installation') { result = {...result, projectType: result.projectType.concat(prTyp)}; }
                    });
                }
                // console.log('result', result);
                fetch(this.getDomain() + "/api/gewerke", {
                    method: "get", mode: "cors",
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + result.token }
                }).then(r => r.json()).then(rs => {
                    // console.log('rs', rs)
                    result = {...result, gewerke: rs['hydra:member']};
                    callFunction(data, result)
                });
            });
        }).catch(e => callFunction(data, e));
    }
    static sendProject(data, token, callFunction){
        fetch(
            this.getDomain() + "/api/projekt",
            {
                method: "post",
                mode: "cors",
                headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token, },
                body: JSON.stringify(data)
            }
        ).then(res => res.json()).then(result => {
            callFunction(result)
        }).catch(callFunction);
    }

    static uploadImages(files, project, token, callFunction) {
        if (files.length) {
            console.log('files[0].file=', files[0].file);
            fetch((files[0].file)).then(data => {
                data.blob().then(blob => {
                    console.log('blob', blob);
                    let data = new FormData();
                    data.append('file', blob, 'file1.jpg');
                    data.append('projekt', project['@id']);
                    data.append('altText', files[0].altText);
                    data.append('titleText', files[0].titleText);
                    fetch(
                        this.getDomain() + "/api/projekt_bilder",
                        {
                            method: "post",
                            mode: "cors",
                            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token, },
                            body: data
                        }
                    ).then(res => res.json()).then(result => {
                        files = files.splice(0,1);
                        // this.uploadImages(files, project, token, callFunction);
                        callFunction(2);
                    }).catch(err => console.log('failed to upload file', blob, project['@id']));
                })
                // data.body.getReader().read().then(res=> {
                //     console.log('body', res)
                // })
            });
            return;
            const filereader = new FileReader();
            filereader.readAsDataURL(new Blob([files[0].file], {type: 'image/jpeg'}));
            filereader.onload = (event) => {
                console.log('read file', event.target.result, event);
                const blob = event.target.result;
                let data = new FormData();
                console.log('blob', blob);
                data.append('file', new Blob([blob], {type: 'image/jpeg'}), 'file1.jpg');
                data.append('projekt', project['@id']);
                data.append('altText', files[0].altText);
                data.append('titleText', files[0].titleText);
                fetch(
                    this.getDomain() + "/api/projekt_bilder",
                    {
                        method: "post",
                        mode: "cors",
                        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token, },
                        body: data
                    }
                ).then(res => res.json()).then(result => {
                    files = files.splice(0,1);
                    // this.uploadImages(files, project, token, callFunction);
                    callFunction(2);
                }).catch(err => console.log('failed to upload file', blob, project['@id']));
            };
            return;
            const convertBase64 = (file) => {
                return new Promise((resolve, reject) => {
                    const filereader = new FileReader();
                    filereader.readAsDataURL(file);
                    filereader.onload = () => {
                        resolve(filereader.result);
                    };
                });
            };
            convertBase64(new Blob([files[0].file], {type: 'image/*'})).then(blob => {
                let data = new FormData();
                console.log('blob', blob); return;
                data.append('file', blob);
                data.append('projekt', project['@id']);
                data.append('altText', files[0].altText);
                data.append('titleText', files[0].titleText);
                fetch(
                    this.getDomain() + "/api/projekt",
                    {
                        method: "post",
                        mode: "cors",
                        headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token, },
                        body: data
                    }
                ).then(res => res.json()).then(result => {
                    files = files.splice(0,1);
                    // this.uploadImages(files, project, token, callFunction);
                    callFunction(2);
                }).catch(err => console.log('failed to upload file', blob, project['@id']));
            });
            return;

            fetch(files[0].file).then(blob => {
                blob.body.getReader().read().then(res=>console.log('body', res));
                blob.body.getReader().releaseLock();
                blob.text().then(txt => console.log('txt', txt));
                let data = new FormData();
                const reader = new FileReader();
                reader.onload = (event) => {
                    console.log('read file', event.target.result, event, blob);
                    return;
                    data.append('file', blob);
                    data.append('projekt', project['@id']);
                    data.append('altText', files[0].altText);
                    data.append('titleText', files[0].titleText);
                    fetch(
                        this.getDomain() + "/api/projekt",
                        {
                            method: "post",
                            mode: "cors",
                            headers: { 'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + token, },
                            body: data
                        }
                    ).then(res => res.json()).then(result => {
                        files = files.splice(0,1);
                        // this.uploadImages(files, project, token, callFunction);
                        callFunction(2);
                    }).catch(err => console.log('failed to upload file', blob, project['@id']));
                };
                reader.readAsBinaryString(blob.body);
            });
        } else {
            callFunction(2);
        }
    }

    // static loadProject(code, startWithProject, isMyJung = false){
    //     fetch(
    //         this.getDomain() + "/configurator-api/quickplaner/load/" + code,
    //         {
    //             method: "get",
    //             mode: "cors"
    //         }
    //     ).then(res => res.json()).then(result => {
    //         startWithProject({...result.dataQ, isMyJung: isMyJung, loadedFromCode: true})
    //     }).catch(e => alert("Code not supported!"));
    // }
}
