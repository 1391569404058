
export default function GenerateProductList(project, selectedRoomFilter, selectedProductFilter, roomProductFilters, selectedRoomProductFilter, onlyWithProductSwitch = false){

    const list = [];
    let categories = [];

    const roomFilterList = [];
    let productList = [];
    let catFilterList = [];

    const selectedFloor = selectedRoomFilter && selectedRoomFilter.floor !== undefined && selectedRoomFilter.floor !== 'all' ? project.generatedProject.floors.filter(f => f.id === selectedRoomFilter.floor)[0] : undefined;
    const selectedRoom = selectedRoomFilter && selectedRoomFilter.floor !== undefined && selectedRoomFilter.floor !== 'all' && selectedRoomFilter.room !== undefined ? selectedFloor.rooms.filter(i => i.id === selectedRoomFilter.room)[0] : undefined;
    const isAllRooms = selectedRoomFilter && selectedRoomFilter.floor === 'all';

    const reihenfolge = [
        "Bedienstellen",
        "Tastsensoren",
        "Tastsensor",
        "Heizung",
        "Heizungsventile",
        "Steckdose",
        "Steckdosen",
        "Mediendosen",
        "Rahmen",
        "Türkommunikation",
        "Radio",
        "Aktor",
        "Aktoren",
        "Systemgeräte",
        "Sensoren",
        "Server"
    ]
    const optionalCategory = "Mediendosen";
    let optionalProducts = [];
    let optionalProductsParsed = [];
    if (isAllRooms) {
        project.generatedProject.floors.forEach( floor => {
            const floorTmp = {
                id: floor.id,
                name: floor.name,
                rooms: []
            }
            floor.rooms.forEach(room => {
                let roomTmp = {
                    id: room.id,
                    name: room.name,
                    products: []
                };
                room.products.map(p => {
                    if (categories.filter(k => k === p.kategorie)[0] === undefined) {
                        categories.push(p.kategorie);
                        list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                    }
                    if(selectedProductFilter !== undefined && selectedProductFilter !== p.kategorie){ return p; }
                    list[p.kategorie].products.push({...p, roomId: room.id, floorId: floor.id, roomName: room.name, floorName: floor.name});
                    roomTmp.products.push({...p, roomId: room.id, floorId: floor.id, roomName: room.name, floorName: floor.name});
                    if (!list[p.kategorie].productKeys.includes(p.Artikelnummer)) { list[p.kategorie].productKeys.push(p.Artikelnummer); }
                    return p;
                });
                roomTmp = {
                    ...roomTmp,
                    products: roomTmp.products.sort((a, b) => reihenfolge.indexOf(a.kategorie) - reihenfolge.indexOf(b.kategorie)),
                };
                floorTmp.rooms.push(roomTmp);
            });
            roomFilterList.push(floorTmp);
        });
    }

    if (!isAllRooms) {
        project.generatedProject.floors.forEach(floor => {
            const floorTmp = {
                id: floor.id,
                name: floor.name,
                rooms: []
            }
            floor.rooms.forEach(room => {
                if (
                    selectedRoomFilter !== undefined
                    && selectedRoomFilter.room !== undefined
                    && selectedRoomFilter.room !== room.id
                ) {
                    if (selectedProductFilter !== undefined) {
                        const found = room.products.filter(p => p.kategorie === selectedProductFilter)[0];
                        if (found !== undefined) {
                            floorTmp.rooms.push({
                                id: room.id,
                                name: room.name
                            });
                            room.products.forEach(p => {
                                if (
                                    categories.filter(k => k === p.kategorie)[0] === undefined &&
                                    selectedRoom.products.filter(rp => rp.kategorie === p.kategorie)[0] !== undefined
                                ) {
                                    categories.push(p.kategorie);
                                    list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                                }
                            });
                        }
                        return;
                    }
                    floorTmp.rooms.push({
                        id: room.id,
                        name: room.name
                    });
                    return;
                }
                let addRoomToFilter = false;
                room.products.forEach(p => {
                    if (p.isOptional) {
                        optionalProductsParsed.push(p);
                    } else {
                        p = {...p, roomId: room.id, roomName: room.name, floorId: floor.id, floorName: floor.name};
                        if (
                            (
                                selectedProductFilter !== undefined
                                && selectedProductFilter !== p.kategorie
                            )
                            ||
                            (
                                onlyWithProductSwitch
                                && p.productSwitch === undefined
                            )
                        ) {
                            if (list[p.kategorie] === undefined) {
                                categories.push(p.kategorie);
                                list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                            }
                            return;
                        }
                        addRoomToFilter = true;
                        if (list[p.kategorie] === undefined) {
                            categories.push(p.kategorie);
                            list[p.kategorie] = {name: p.kategorie, products: [], productKeys: []};
                        }
                        if (list[p.kategorie].products.filter(px => px.Artikelnummer === p.Artikelnummer)[0] === undefined) {
                            list[p.kategorie].productKeys.push(p.Artikelnummer);
                            list[p.kategorie].products.push(p);
                        } else {
                            list[p.kategorie].products = list[p.kategorie].products.map(lp => {
                                if (lp.Artikelnummer !== p.Artikelnummer) {
                                    return lp;
                                }
                                return {...lp, count: lp.count + p.count};
                            });
                        }
                    }
                });
                if (addRoomToFilter) {
                    floorTmp.rooms.push({
                        id: room.id,
                        name: room.name
                    });
                }
            });
            if (floorTmp.rooms.length > 0) {
                roomFilterList.push(floorTmp);
            }
        });
    }
    categories = categories.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));
    categories.forEach( c => {
        const cat = {name: list[c].name, products: []}
        list[c].productKeys.forEach(pk => {
            list[c].products.map(lp => {
                if (pk === lp.Artikelnummer) { cat.products.push(lp); }
                return lp;
            });
        });
        productList.push(cat);
        catFilterList.push({
            id: cat.name,
            name: cat.name
        });
    });
    productList = productList.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));
    catFilterList = catFilterList.sort((a,b) => reihenfolge.indexOf(a.name) - reihenfolge.indexOf(b.name));
    if (
        (project.projectType === 'konventionell' && project.subProjectType === 'umbau') ||
        (project.projectType === 'home' && project.subProjectType === "umbau")
    ) {
        optionalProducts = productList.filter(i => i.name === optionalCategory) || [];
        productList = productList.filter(g => g.name !== optionalCategory);
        productList.forEach(g => {
            g.products.forEach(p => {
                if (p.isOptional) {
                    if (optionalProducts.filter(i => i.name === g.name)[0] === undefined) {
                        optionalProducts.push({...g, products: []});
                    }
                    optionalProducts = optionalProducts.map(og => {
                        if (og.name !== g.name) { return og; }
                        return { ...og, products: [...og.products, p] };
                    });
                }
            });
        });
        optionalProductsParsed.forEach(op => {
            if (optionalProducts.filter(i => i.name === op.kategorie)[0] === undefined) {
                optionalProducts.push({name: op.kategorie, products: []});
            }
            optionalProducts = optionalProducts.map(og => {
                if (og.name !== op.kategorie) { return og; }
                return { ...og, products: [...og.products, op] };
            });
        });
        productList = productList.map(pg => { return {...pg, products: pg.products.filter(i => !i.isOptional)}; });
    }
    let roomName = 'alle';
    if (selectedRoomFilter && selectedRoomFilter.floor !== undefined && selectedRoomFilter.floor !== 'all' && selectedRoomFilter.room !== undefined) {
        roomName = selectedFloor.name + ' - ';
        roomName += selectedFloor.rooms.filter(i => i.id === selectedRoomFilter.room)[0].name;
    }
    return {
        productList: productList,
        catFilterList: catFilterList,
        roomFilterList: roomFilterList,
        isAllRooms: isAllRooms,
        room: roomName,
        product: selectedProductFilter,
        optionalProducts: optionalProducts,
        isAllProducts: selectedFloor === undefined && selectedRoom === undefined && selectedProductFilter === undefined
    };
}
