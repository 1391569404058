import React from "react";
import StarIcon from "@material-ui/icons/Star";
import {Tooltip, withStyles} from "@material-ui/core";
// import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
// import iIcon from "../images/info_icon.svg";
import iIconActive from "../images/info_icon_active.svg";
import Button from "@material-ui/core/Button";
import iIconBlue from "../images/info_icon_blue.svg";
import Popover from "@material-ui/core/Popover";
import CloseIcon from "@material-ui/icons/Close";

export default function SelectConfLevelComponent({selectedLevel, setLevel, justStars = false, clickAble = true}){

    const levelList = [
        {
            id: "one_star",
            value: 1,
            stars: <StarIcon />,
            description: "Basic",
            hoverText1: "Mindestausstattung für Wohnflächen bis 100qm",
            hoverText2: "HEA \"Ein-Stern-Ausstattung\" entspricht der Mindestausstattung einer elektrischen Anlage in Wohngebäuden."
        },
        {
            id: "two_star",
            value: 2,
            stars: [<StarIcon key="1"/>,<StarIcon key="2"/>],
            description: "Komfort",
            hoverText1: "Standardausstattung für Wohnflächen bis 180qm",
            hoverText2: "HEA \"Zwei-Sterne-Ausstattung\" entspricht einer gehobenen Ausstattung, die es erlaubt, heute übliche Elektrogeräte ohne räumliche und zeitliche Einschränkungen anzuschließen."
        },
        {
            id: "three_star",
            value: 3,
            stars: [<StarIcon key="1"/>,<StarIcon key="2"/>,<StarIcon key="3"/>],
            description: "Premium",
            hoverText1: "Komfortausstattung für Wohnflächen ab 180qm",
            hoverText2: "HEA \"Drei-Sterne-Ausstattung\" entspricht einer Elektroausstattung für Komfortwohnungen/-häuser, die über den Ausstattungswert 2 hinausgeht und die diverse neue Techniken berücksichtigt (Sicherheit, Kommunikation, Komfort, Automatik etc.)"
        }
    ];

    // const InfoOutlinedIconCustom = withStyles((theme) => ({
    //     root: {
    //         color: "var(--grau3)",
    //         fontSize: "18px",
    //         verticalAlign: "middle",
    //         paddingLeft: "5px"
    //     },
    // }))(InfoOutlinedIcon);
    // const InfoOutlinedIconCustomActive = withStyles((theme) => ({
    //     root: {
    //         color: "var(--weiss)",
    //         fontSize: "18px",
    //         verticalAlign: "middle",
    //         paddingLeft: "5px",
    //         fill: "var(--weiss) !important"
    //     },
    // }))(InfoOutlinedIcon);
    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: "var(--weiss)",
            color: "var(--textColor)",
            fontSize: "14px",
            border: '1px solid #dadde9',
            maxWidth: '350px',
            minWidth: '150px',
            boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
            padding: '25px'
        },
    }))(Tooltip);
    function TooltipWithButton(props) {
        const el = document.createElement('a');
        document.body.appendChild(el);
        const [anchorEl, setAnchorEl] = React.useState(null);
        const [opened, setOpened] = React.useState(false);
        const id = 'popover_' + ((Math.random() * 1000) + 1);
        // const open = Boolean(anchorEl);
        const params = props.props;
        // setTimeout(() => {if (!opened) {document.body.style.overflow = 'unset';}}, 100);
        const handleClick = (event) => {
            event.preventDefault(); event.stopPropagation();
            setAnchorEl(event.currentTarget); setOpened(true);
            // document.body.style.overflow = 'hidden';
            return false;
        };
        const handleClose = (event) => {
            event.preventDefault(); event.stopPropagation();
            setOpened(false);
            // document.body.style.overflow = 'unset';
            return false;
        };
        return <>
            <Button aria-describedby={id} variant="contained" onClick={handleClick} className={"button-without-style" + (!params.active ? ' inactive' : '')}>
                {params.active
                    ? <span className="info-icon">
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"}/>
                    </span>
                    : <span className="info-icon">
                        <img src={iIconBlue} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"hideOnHover"}/>
                        <img src={iIconActive} width="32" height="32" style={{marginTop: "-14px"}} alt={"info"} className={"showOnHover"}/>
                    </span>
                }
            </Button>
            <Popover id={id} open={opened} anchorEl={anchorEl} onClose={handleClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'left',
                     }}
                     style={{maxWidth: window.innerWidth > 800 ? '75%' : '100%', visibility: opened ? 'visible' : 'hidden'}}
            >
                <div className={"popoverContainer"} style={{color: 'var(--blueColor)', padding: '15px', position: 'relative'}}>
                    <a href={"/"} onClick={handleClose} style={{position: 'absolute', top: '5px', right: '5px', color: 'var(--blueColor)'}}><CloseIcon /></a>
                    <p style={{fontWeight: 'bold'}}>{params.title}</p>
                    <p>{params.message}</p>
                </div>
            </Popover>
        </>;
    }
    function catchClick(e) {
        e.preventDefault();
        e.stopPropagation();
        return false;
    }
    if (justStars) {
        return <div className="starsBox" style={{float: "right", marginTop: "-3%", whiteSpace: 'nowrap'}}>
            <HtmlTooltip enterTouchDelay={50} title={<div style={{color: 'var(--blueColor)'}}><p style={{fontWeight: "bold", fontSize: '16px', display: 'none'}}>{levelList[0].hoverText1}</p><p style={{fontWeight: "normal"}}>{levelList[0].hoverText2}</p></div>}>
                <StarIcon key="1" htmlColor={"var(--blueColor)"} onClick={e => clickAble ? setLevel(e, 1) : catchClick(e)}/>
            </HtmlTooltip>
            <HtmlTooltip enterTouchDelay={50} title={<div style={{color: 'var(--blueColor)'}}><p style={{fontWeight: "bold", fontSize: '16px', display: 'none'}}>{levelList[1].hoverText1}</p><p style={{fontWeight: "normal"}}>{levelList[1].hoverText2}</p></div>}>
                <StarIcon key="1" htmlColor={selectedLevel >= 2 ?"var(--blueColor)" : "--schwarz"} onClick={e => clickAble ? setLevel(e, 2) : catchClick(e)}/>
            </HtmlTooltip>
            <HtmlTooltip enterTouchDelay={50} title={<div style={{color: 'var(--blueColor)'}}><p style={{fontWeight: "bold", fontSize: '16px', display: 'none'}}>{levelList[2].hoverText1}</p><p style={{fontWeight: "normal"}}>{levelList[2].hoverText2}</p></div>}>
                <StarIcon key="1" htmlColor={selectedLevel >= 3 ?"var(--blueColor)" : "--schwarz"} onClick={e => clickAble ? setLevel(e, 3) : catchClick(e)}/>
            </HtmlTooltip>
        </div>;
    }

    return <div className="SelectConfLevelComponentWrapper row">
        { levelList.map( (item, ix) =>
            <div style={{display: 'flex', flexDirection: 'row'}} key={item.id} className={"col-1 levelSelectBox" + (item.value === selectedLevel ? " active" : "")} onClick={(e) => {e.preventDefault(); e.stopPropagation(); setLevel(e, item.value); return false;}}>
                <div className="starsBox" style={{float: "left"}}>{item.stars}</div>
                <div className="description" style={{margin: '0', flexGrow: '1', textAlign: 'center', fontSize: '32px', color: item.value === selectedLevel ? 'white' : 'var(--captionColor)'}}>{item.description}</div>
                <div className="info-icon" style={{float: "right"}}>
                    {/*<HtmlTooltip enterTouchDelay={50} title={<div style={{color: 'var(--blueColor)'}}><p style={{fontWeight: "bold", fontSize: '16px', display: 'none'}}>{item.hoverText1}</p><p style={{fontWeight: "normal"}}>{item.hoverText2}</p></div>}>*/}
                    {/*    <span className="info-icon">*/}
                    {/*        <img src={(item.value === selectedLevel) ? iIconActive : iIcon} width="32" height="32" style={{top: "-2px"}} alt={"info"} />*/}
                    {/*    </span>*/}
                    {/*</HtmlTooltip>*/}
                    <TooltipWithButton props={{title: item.hoverText1, message: item.hoverText2, active: item.value === selectedLevel}} />
                </div>
            </div>
        )}
    </div>
}
