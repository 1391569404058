import matrix from '../../data/home_dateimatrix.json'
import matrix_categories from '../../data/home_matrix_categories.json'
import matrix_filenames from '../../data/home_dateinamen.json'

export default function getFunctions(productlist){
    let list = [];
    let tmpList = {};

    productlist.forEach(p => {
        const found = matrix.filter(i => i.Artikelnummer === p.product)[0];
        if (found !== undefined) {
            found.Files.forEach(f => {
                const category = matrix_categories.filter(i => i.files.includes(f))[0];
                if (category) {
                    if (tmpList[category.cat]) {
                        tmpList = {
                            ...tmpList,
                            [category.cat]: {
                                ...tmpList[category.cat],
                                files: [...tmpList[category.cat].files.filter(i => i !== f), f]
                            }
                        };
                    } else {
                        tmpList = {
                            ...tmpList,
                            [category.cat]: {
                                cat: category.cat,
                                files: [f]
                            }
                        };
                    }
                }
            });
        }
    });
    Object.keys(tmpList).forEach(key => {
        const cat = tmpList[key];
        const filename = matrix_filenames.filter(i => i.id === cat.cat)[0];
        if (filename) { list.push( filename.file); }
        cat.files.forEach(f => {
            const fname = matrix_filenames.filter(i => i.id === f)[0];
            if (fname) { list.push( fname.file); }
        });
    });
    return {list, cats: tmpList};
}

