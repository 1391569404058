

export default function getProducts(project, austattung_produkt, generatedProject){
    const matrix_rooms = [
        {product: "bat-geklebt-taster+", rooms: ["56b1b694-958d-4b4e-ae51-88967c88f8d9", "b8290c0e-368c-4747-a891-57ebb6fd0389", "56b1b694-958d-4b4e-ae51-88967c88f8d9"]},
        {product: "szenen", rooms: ["10f41afa-353f-4aac-bee2-874c5899e562"]},
        {product: "zeit-beleuchtung-aufsatz", rooms: ["46e3e159-d5a5-4623-9cd6-8f66f5fad7fa", "d8cf675b-2a77-42b9-8be7-5b9f7b6ff4f9", "46e3e159-d5a5-4623-9cd6-8f66f5fad7fa"]},
        {product: "dali-einsatz", rooms: ["4b3a0860-836b-499f-8e28-f11c7f46e8e1"]},
    ];
    function getRoomForProduct(p) {
        const recommended = matrix_rooms.filter(i => i.product === p)[0];
        let foundRoom = generatedProject.floors[0].rooms[0];
        if (recommended) {
            generatedProject.floors.forEach(floor => {
                floor.rooms.forEach(room => {
                    if (recommended.rooms.includes(room.type)) { foundRoom = room; }
                });
            });
        }
        return foundRoom;
    }

    const productList = [];
    if (project.selectedEquipment["smart-haus-licht"] !== undefined
        && Boolean(project.selectedEquipment["smart-haus-licht"])
    ) {
        productList.push({
            product: austattung_produkt["bat-geklebt-taster+"],
            count: 1,
            room: getRoomForProduct("bat-geklebt-taster+")
        })
        productList.push({
            product: austattung_produkt["bat-geklebt-wippe"],
            count: 1,
            room: getRoomForProduct("bat-geklebt-taster+")
        })
    }
    if (project.selectedEquipment["smart-haus-aktor"] !== undefined
        && Boolean(project.selectedEquipment["smart-haus-aktor"])
    ) {
        if (project.selectedEquipment["smart-haus-aktor"] > 0) {
            productList.push({
                product: austattung_produkt["szenen"],
                count: 1,
                room: getRoomForProduct("szenen")
            })
        }
    }
    if (project.selectedEquipment["smart-haus-bewegung"] !== undefined
        && Boolean(project.selectedEquipment["smart-haus-bewegung"])
    ) {
        if (project.selectedEquipment["smart-haus-bewegung"] > 0) {
            productList.push({
                product: austattung_produkt["zeit-beleuchtung-aufsatz"],
                count: 1,
                room: getRoomForProduct("zeit-beleuchtung-aufsatz")
            })
            productList.push({
                product: austattung_produkt["scha-einsatz-1"],
                count: 1,
                room: getRoomForProduct("zeit-beleuchtung-aufsatz")
            })
        }
    }
    if (project.selectedEquipment["smart-haus-dali"] !== undefined
        && Boolean(project.selectedEquipment["smart-haus-dali"])
    ) {
        if (project.selectedEquipment["smart-haus-dali"] > 0) {
            productList.push({
                product: austattung_produkt["dali-einsatz"],
                count: 1,
                room: getRoomForProduct("dali-einsatz")
            })
            productList.push({
                product: austattung_produkt["scha-aufsatz-1"],
                count: 1,
                room: getRoomForProduct("dali-einsatz")
            })
            productList.push({
                product: austattung_produkt["r-1f"],
                count: 1,
                room: getRoomForProduct("dali-einsatz")
            })
        }
    }

    return productList;
}
